<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!salesInvoice">
      <span class="loader"></span>
    </span>
    <sales-invoice-form
      v-if="salesInvoice"
      :loading="loading"
      :salesInvoiceData="salesInvoice"
      :formErrors="formErrors"
      @salesInvoiceSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import SalesInvoiceForm from "../partials/SalesInvoiceForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";

export default {
  layout: "DashboardLayout",

  components: { SalesInvoiceForm },

  mixins: [alertLeave, requestErrorMixin],

  props: {
    salesInvoiceId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      salesInvoice: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("salesInvoices/get", this.salesInvoiceId);
        this.salesInvoice = this.$store.getters["salesInvoices/salesInvoice"];
        this.loading = false;
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    async handleSubmit(salesInvoice) {
      this.loading = true;
      const salesInvoiceData = cloneDeep(salesInvoice);
      try {
        await this.$store.dispatch("salesInvoices/update", salesInvoiceData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("SALES_INVOICES.SALES_INVOICE_UPDATED"),
        });
        const salesInvoice = await this.$store.getters[
          "salesInvoices/salesInvoice"
        ];
        this.$emit("onViewSalesInvoice", salesInvoice, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
