var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-title"},[_c('i',{staticClass:"fa-light fa-file-invoice"}),_c('h1',[_vm._v(_vm._s(_vm.$t("COMMON.SALES_INVOICES")))])]),_c('div',{staticClass:"page-wrapper"},[_c('div',{staticClass:"page-wrapper-header",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("SALES_INVOICES.SALES_INVOICES_LIST"))+" ")])]),_c('div',{staticClass:"col-6 text-right"},[(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_SALES_INVOICES))?_c('base-button',{staticClass:"elite-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.openSalesInvoiceCreateModal}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"far fa-plus-circle"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.$t("SALES_INVOICES.ADD_SALES_INVOICE")))])]):_vm._e()],1)])]),_c('sales-invoice-list-table',{key:_vm.renderKey * 100,on:{"onViewSalesInvoice":_vm.openSalesInvoiceViewModal,"onEditSalesInvoice":_vm.openSalesInvoiceEditModal,"onDeleteSalesInvoice":_vm.deleteSalesInvoice}}),(_vm.isViewSalesInvoiceModalOpened)?_c('div',{key:_vm.renderKey * 200,staticClass:"resizable-wrapper",class:[_vm.isViewSalesInvoiceModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('SALES_INVOICE'),expression:"'SALES_INVOICE'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":function($event){return _vm.closeSalesInvoiceModal(true, true)}}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("SALES_INVOICES.VIEW_SALES_INVOICE"))+" ")])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[(
                _vm.openSalesInvoiceLoaded
                  ? _vm.openSalesInvoice.items.length > 0 &&
                    _vm.openSalesInvoice.status === _vm.INVOICE_STATUS_DRAFT
                  : false
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","title":_vm.$t('SALES_INVOICES.VALIDATE')},on:{"click":function($event){return _vm.validateSalesInvoice(_vm.openSalesInvoice)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-check-bold"})])]):_vm._e(),(
                _vm.openSalesInvoiceLoaded
                  ? _vm.openSalesInvoice.status === _vm.INVOICE_STATUS_VALIDATED
                  : false
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","title":_vm.$t('SALES_INVOICES.ADD_PAYMENT')},on:{"click":function($event){return _vm.addSalesPayment(_vm.openSalesInvoice)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-money-coins"})])]):_vm._e(),(
                _vm.openSalesInvoiceLoaded ? _vm.openSalesInvoice.salesOrder : false
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm","title":_vm.$t('SALES_INVOICES.VIEW_ORDER')},on:{"click":function($event){return _vm.viewSalesOrder(_vm.openSalesInvoice)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-bag-17"})])]):_vm._e(),(_vm.canCancelSalesInvoice)?_c('base-button',{attrs:{"type":"danger","icon":"","size":"sm","title":_vm.$t('SALES_INVOICES.CANCEL')},on:{"click":function($event){return _vm.cancelSalesInvoice(_vm.openSalesInvoice)}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"ni ni-fat-remove"})])]):_vm._e(),(_vm.openSalesInvoice)?_c('notification-subscription',{attrs:{"objectType":'SalesInvoices',"objectId":_vm.openSalesInvoice.id,"events":{
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}}):_vm._e(),_c('base-dropdown',{attrs:{"title-classes":"btn dropdown-button","menu-on-right":"","has-toggle":false}},[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-ellipsis-v"})]),(
                  _vm.openSalesInvoice.status === _vm.INVOICE_STATUS_DRAFT &&
                  _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SALES_INVOICES)
                )?_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.openSalesInvoiceEditModal(_vm.openSalesInvoice)}}},[_c('i',{staticClass:"fal fa-edit"}),_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.EDIT")))])]):_vm._e(),(
                  _vm.openSalesInvoice.status === _vm.INVOICE_STATUS_DRAFT &&
                  _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SALES_INVOICES)
                )?_c('button',{staticClass:"edit",on:{"click":function($event){return _vm.deleteSalesInvoice(_vm.openSalesInvoice)}}},[_c('i',{staticClass:"fal fa-trash-alt"}),_c('span',[_vm._v(_vm._s(_vm.$t("COMMON.DELETE")))])]):_vm._e()],2),_c('button',{staticClass:"close",on:{"click":function($event){return _vm.closeSalesInvoiceModal(true)}}},[_c('i',{staticClass:"fal fa-times"})])],1)]),_c('div',{staticClass:"resizable-wrapper-content-body"},[(_vm.openSalesInvoice)?_c('view-sales-invoice-page',{attrs:{"salesInvoiceId":_vm.openSalesInvoice.id},on:{"salesInvoiceLoaded":(salesInvoice) => {
                _vm.openSalesInvoice = salesInvoice;
                _vm.openSalesInvoiceLoaded = true;
              }}}):_vm._e()],1)])]):_vm._e(),(_vm.isEditSalesInvoiceModalOpened)?_c('div',{key:_vm.renderKey * 300,staticClass:"resizable-wrapper",class:[_vm.isEditSalesInvoiceModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('SALES_INVOICE'),expression:"'SALES_INVOICE'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeSalesInvoiceModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('div',{staticClass:"resizable-wrapper-content-header-left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("SALES_INVOICES.EDIT_SALES_INVOICE"))+" ")])]),_c('div',{staticClass:"resizable-wrapper-content-header-right"},[_c('button',{staticClass:"close",on:{"click":function($event){return _vm.openSalesInvoiceViewModal(_vm.openSalesInvoice)}}},[_c('i',{staticClass:"fal fa-times"})])])]),_c('div',{staticClass:"resizable-wrapper-content-body"},[(_vm.openSalesInvoice)?_c('edit-sales-invoice-page',{attrs:{"salesInvoiceId":_vm.openSalesInvoice.id},on:{"onViewSalesInvoice":_vm.openSalesInvoiceViewModal}}):_vm._e()],1)])]):_vm._e(),(_vm.isAddSalesInvoiceModalOpened)?_c('div',{key:_vm.renderKey * 400,staticClass:"resizable-wrapper",class:[_vm.isAddSalesInvoiceModalOpened ? 'show' : 'hide']},[_c('div',{directives:[{name:"resizable-modal",rawName:"v-resizable-modal",value:('SALES_INVOICE'),expression:"'SALES_INVOICE'"}],staticClass:"resizable-wrapper-content",on:{"onCloseModal":_vm.closeSalesInvoiceModal}},[_c('div',{staticClass:"resizable-wrapper-content-header"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("SALES_INVOICES.ADD_SALES_INVOICE"))+" ")]),_c('button',{staticClass:"close",on:{"click":_vm.closeSalesInvoiceModal}},[_c('i',{staticClass:"fal fa-times"})])]),_c('div',{staticClass:"resizable-wrapper-content-body"},[_c('add-sales-invoice-page',{on:{"onViewSalesInvoice":_vm.openSalesInvoiceViewModal}})],1)])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }