<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="salesInvoice.items"
          >
            <el-table-column :label="$t('COMMON.CODE')" prop="code">
              <template v-slot="{ row }">
                <div v-if="row.salesInvoiceable">
                  <a
                    href="#"
                    @click.prevent="goToSalesInvoiceable(row.salesInvoiceable)"
                  >
                    {{ row.code }}<br />
                    <div
                      class="text-muted sales-invoice-item-excerpt"
                      v-html="row.excerpt"
                    ></div>
                  </a>
                </div>
                <div v-if="!row.salesInvoiceable">
                  {{ row.code }}<br />
                  <div
                    class="text-muted sales-invoice-item-excerpt"
                    v-html="row.excerpt"
                  ></div>
                </div>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.UNIT_PRICE')" prop="unit_price">
              <template v-slot="{ row }"> ${{ row.unit_price }} </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.QUANTITY')" prop="quantity" />

            <el-table-column :label="$t('COMMON.SUBTOTAL')" prop="discount">
              <template v-slot="{ row }">
                ${{ row.pricing.subtotal.toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.DISCOUNT')" prop="discount">
              <template v-slot="{ row }">
                ${{ row.pricing.discount_amount.toFixed(2) }}
                <span class="text-muted">({{ row.discount }}%)</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.TAXES')">
              <template v-slot="{ row }">
                ${{ row.pricing.taxes.total.toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('COMMON.TOTAL')">
              <template v-slot="{ row }">
                ${{ row.pricing.total.toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column :fixed="$listActionsButtonsPosition()">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="salesInvoice.status === INVOICE_STATUS_DRAFT"
                >
                  <a
                    type="text"
                    @click="openSalesInvoiceItemModal(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { INVOICE_STATUS_DRAFT } from "@/constants/invoices";

export default {
  name: "sales-invoice-view-details-sales-invoice-item-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["salesInvoice"],

  data() {
    return {
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
    };
  },

  computed: {},

  methods: {
    openSalesInvoiceItemModal(salesInvoiceItem) {
      this.$emit("onOpenSalesInvoiceItemModal", salesInvoiceItem);
    },

    async goToSalesInvoiceable(salesInvoiceable) {
      this.$router.push(this.$objectViewRoute(salesInvoiceable));
    },
  },

  mounted() {},

  watch: {},
};
</script>

<style>
.sales-invoice-item-excerpt p,
.sales-invoice-item-excerpt,
.sales-invoice-item-excerpt * {
  font-size: 0.7rem;
}
</style>
